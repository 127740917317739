<template>
    <div id="dropdown-component">
        <!-- VendorListComponent -->
        <div v-if="params.componentName == 'CustomerList'">
            <b-dropdown id="dropdown-grouped" size="sm" variant="outline-primary"
                class="dropdown-icon-wrapper custom-dropdown btn-icon">
                <template #button-content>
                    <feather-icon icon="SettingsIcon" size="12" class="align-middle spinner" />
                </template>
                <b-dropdown-item variant="success" @click="params.onButtonClick(params.data, 'Enable')"> <feather-icon
                        icon="UserCheckIcon" size="18" /> Activer </b-dropdown-item>
                <b-dropdown-item variant="danger" @click="params.onButtonClick(params.data, 'Disable')"> <feather-icon
                        icon="UserXIcon" size="18" /> Désactiver </b-dropdown-item>
                <!-- <b-dropdown-item variant="secondary" @click="params.onButtonClick(params.data, 'Password')"> <feather-icon
                        icon="LockIcon" size="18" /> Modifier mot de passe
                </b-dropdown-item> -->
                <b-dropdown-item v-if="params.enableStat" variant="warning" @click="$router.replace({
                    name: 'customer-dashboard', params: { slug: params.data.No },
                })"> <feather-icon icon="PieChartIcon" size="18" />
                    Statistique
                </b-dropdown-item>
            </b-dropdown>
        </div>
        <!-- VendorListComponent -->

        <!-- Users -->
        <div v-if="params.componentName == 'Users'">
            <b-dropdown id="dropdown-grouped" size="sm" variant="outline-primary"
                class="dropdown-icon-wrapper custom-dropdown btn-icon">
                <template #button-content>
                    <feather-icon icon="SettingsIcon" size="12" class="align-middle spinner" />
                </template>
                <b-dropdown-item variant="success" @click="params.onButtonClick(params.data, 'Enable')"> <feather-icon
                        icon="UserCheckIcon" size="18" /> Activer </b-dropdown-item>
                <b-dropdown-item variant="danger" @click="params.onButtonClick(params.data, 'Disable')"> <feather-icon
                        icon="UserXIcon" size="18" /> Désactiver </b-dropdown-item>
                <b-dropdown-item variant="secondary" @click="params.onButtonClick(params.data, 'Password')"> <feather-icon
                        icon="LockIcon" size="18" /> Modifier mot de passe
                </b-dropdown-item>
            </b-dropdown>
        </div>
        <!-- Users -->

        <!-- CompanyList -->
        <div v-if="params.componentName == 'CompanyList'">
            <b-dropdown id="dropdown-grouped" size="sm" variant="outline-primary"
                class="dropdown-icon-wrapper custom-dropdown btn-icon">
                <template #button-content>
                    <feather-icon icon="SettingsIcon" size="12" class="align-middle spinner" />
                </template>
                <b-dropdown-item variant="secondary" @click="params.onButtonClick(params.data, 'Config')"> <feather-icon
                        icon="GitPullRequestIcon" size="18" /> Configuration </b-dropdown-item>
                <b-dropdown-item variant="secondary" @click="params.onButtonClick(params.data, 'User')"> <feather-icon
                        icon="UserCheckIcon" size="18" /> Admin User </b-dropdown-item>
                <b-dropdown-item variant="secondary" @click="params.onButtonClick(params.data, 'Customer')"> <feather-icon
                        icon="ListIcon" size="18" /> Clients
                </b-dropdown-item>
                <b-dropdown-item variant="secondary" @click="params.onButtonClick(params.data, 'Supplier')"> <feather-icon
                        icon="ListIcon" size="18" /> Fournisseurs
                </b-dropdown-item>
                <b-dropdown-item variant="secondary" @click="params.onButtonClick(params.data, 'Item')"> <feather-icon
                        icon="GridIcon" size="18" /> Articles
                </b-dropdown-item>
            </b-dropdown>
        </div>
        <!-- CompanyList -->

    </div>
</template>
  
<script>

export default {
    methods: {
    },
    created() {
        // console.log(this.params);
    }
};
</script>
  
<style scoped>
.custom-dropdown {
    position: static !important;
    /* Override the positioning */
    display: block !important;
    /* Override the display property */
    z-index: 9999;
    /* Adjust the z-index if necessary */
    /* Add any other custom styles as needed */
}
</style>
  