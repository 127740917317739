<template>
    <b-media vertical-align="center">
        <template #aside>
            <b-avatar size="28" :src="params.data.profilePic" :text="avatarText(params.data.firstName)"
                variant="light-primary" />
            <b-link class="font-weight-bold d-block text-nowrap ml-50">
                {{ params.data.firstName + ' ' + params.data.lastName }}
            </b-link>
            <!-- <small class="text-muted">{{ params.data.email }}</small> -->
        </template>
    </b-media>
</template>

<script>
import { avatarText } from '@core/utils/filter'
export default {
    data() {
        return {
            avatarText: avatarText
        }
    }
};
</script>
