<template>
  <div id="users-list">
    <b-overlay :show="show" spinner-variant="primary">
      <b-card>

        <ag-grid :agGrid="agGrid" pageTitle="Liste des utilisateurs" @refresh="loadData" :add="true"
          @plusClicked="$router.push({ name: 'add-new-user' })" />

      </b-card>
    </b-overlay>

  </div>
</template>
  
<script>
import AgGrid from "@/views/components/ag-grid/AgGrid.vue"
import BadgeCellRenderer from "@/views/components/ag-grid/BadgeCellRenderer.vue"
import DropDownCellRenderer from '@/views/components/ag-grid/DropDownCellRenderer.vue';
import ImageUserCellRenderer from "@/views/components/ag-grid/ImageUserCellRenderer.vue";

export default {
  components: {
    AgGrid
  },
  data() {
    return {
      agGrid: {
        columnDefs: [
          {
            headerName: '',
            field: "firstName",
            cellClass: 'centered-customeCellRender',
            cellRendererFramework: 'imageUserCellRenderer',
            // cellRendererParams: { componentName: "Users", onButtonClick: this.customerManagement },
            // sortable: false,
            // resizable: false,
            // filter: false,
            // width: 80
          },
          // {
          //   headerName: "Prénom",
          //   field: "firstName",
          // },
          // {
          //   headerName: "Nom",
          //   field: "lastName",
          // },
          {
            headerName: "Nom d'utilisateur",
            field: "userAccount",
          },
          {
            headerName: "Téléphone",
            field: "phoneNo",
          },
          {
            headerName: "Email",
            field: "email",
          },
          {
            headerName: "Créé le",
            field: "createdAt",
          },
          {
            headerName: "Etat",
            field: "activated",
            cellRendererFramework: 'badgeCellRenderer',
            cellRendererParams: { componentName: "Users" },
          },
          {
            headerName: '',
            cellClass: 'centered-customeCellRender',
            cellRendererFramework: 'dropDownCellRenderer',
            cellRendererParams: { componentName: "Users", onButtonClick: this.customerManagement },
            sortable: false,
            resizable: false,
            filter: false,
            width: 80
          },

        ],
        rows: []
      },
      commandesSelected: [],
      show: true,
    }
  },
  created() {
    this.$options.components.badgeCellRenderer = BadgeCellRenderer;
    this.$options.components.dropDownCellRenderer = DropDownCellRenderer;
    this.$options.components.imageUserCellRenderer = ImageUserCellRenderer
    this.loadData();
  },
  methods: {
    async customerManagement(rowData, action) {
      if (rowData.activated && action == "Enable") {
        console.log("action not allowed !");
        return;
      }
      if (!rowData.activated && action == "Disable") {
        console.log("action not allowed !");
        return;
      }
      this.show = true;
      const VendorDto = {
        username: rowData.userAccount,
        action: action,
      }
      let response = await this.$http.post("pharmacist_assistant/editor", VendorDto).catch(() => {
        this.show = false;
      });
      if (action == "Password") {
        this.$swal({
          title: 'Mot de passe mis à jour avec succès',
          text: response.data,
          icon: 'success',
          customClass: {
            confirmButton: 'btn btn-primary',
          },
          showClass: {
            popup: 'animate__animated animate__flipInX',
          },
          buttonsStyling: false,
        })
      } else {
        this.loadData();
      }
      this.show = false;
    },
    async loadData() {
      this.show = true;
      let response = await this.$http.get("pharmacist_assistant").catch(() => {
        this.show = false;
      });
      this.agGrid.rows = [...response.data.filter(item => item.id != this.$store.state["app-userdata"].AppActiveUser.id)];
      this.show = false;
    },
  }
}
</script>
  
<style lang="scss"></style>
  